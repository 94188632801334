import React, { useState, useEffect, useContext } from 'react'
import css from "./Search.module.css"
import { useGetExploreResults, useGetExploreNonSearchResults } from '../../React-Query/queriesAndMutations'
import { DEFAULTPROFILE } from "../../constants"
import { useInView } from 'react-intersection-observer';
import useDebounce from '../../widgets/Debounce/Debounce'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../AuthContext/AuthContext';
import Loader from "../../Components/Loader/Loader"

const Search = () => {
    const { user, isLoading } = useContext(AuthContext);
    const navigate = useNavigate();
    const { ref, inView } = useInView();
    const [keyword, setKeyword] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const deafultUrl = DEFAULTPROFILE;
    const debouncedValue = useDebounce(keyword, 500)
    const {
        data,
        error,
        isLoading: exploreLoading,
        fetchNextPage,
        hasNextPage
    } = useGetExploreResults(debouncedValue);
    const {
        data: ExploreData,
        error: ExploreError,
        isLoading: isLoadings,
        fetchNextPage: isFetchNextPage,
        hasNextPage: isHasNextPage
    } = useGetExploreNonSearchResults();

    useEffect(() => {
        if (inView) fetchNextPage();
    }, [inView])

    useEffect(() => {
        if (inView) isFetchNextPage();
    }, [inView])

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleNavigate = (index, isUser) => {
        if (isUser) {
            if (windowWidth < 1200) {
                navigate(`/mobile/community/${index}`)
            } else {
                navigate(`/community/${index}`)
            }
        } else {
            navigate(`/explore/${index}`);
        }




    }
    if (isLoading) {
        return <Loader />
    }




    return (
        <div className={css.container}>
            <div className={css.sections}>
                {/* search */}
                <div className={css.searchWidget}>
                    <i class="uil uil-search"></i>
                    <input type="text" placeholder='Search anything here' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                </div>
                {/* results */}
                {
                    isLoading || isLoadings ? <Loader /> : <div className={css.results}>
                        {
                            keyword.length === 0 ?
                                ExploreData && Array.isArray(ExploreData) && ExploreData.map((object, index) => {
                                    return (
                                        <div className={css.CommonCard} key={index} onClick={() => handleNavigate(object._id, false)}>
                                            <img src={object.images[0].url} alt="profile" />
                                            <div className={css.column}>
                                                <span>{object.isgroupBuy === 0 ? "Solo promo" : "Group promo"}</span>
                                                <span>{object.title}</span>
                                            </div>
                                        </div>
                                    );

                                }) :
                                exploreLoading ? <span>Loading...</span> :
                                    data && Array.isArray(data) && data.map((object, index) => {
                                        if (object && object.username) {
                                            if (object._id !== user.user._id) {
                                                return (
                                                    <div className={css.CommonCard} key={index} onClick={() => handleNavigate(object._id, true)}>
                                                        <img src={object.profileimage !== "" ? object.profileimage : deafultUrl} alt="profile" />
                                                        <div className={css.column}>
                                                            <span>{object.username}</span>
                                                            <span>{object.profession !== "" ? object.profession : "Recently Joined"}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                        } else {
                                            return (
                                                <div className={css.CommonCard} key={index} onClick={() => handleNavigate(object._id, false)}>
                                                    <img src={object.images[0].url} alt="profile" />
                                                    <div className={css.column}>
                                                        <span>{object.isgroupBuy === 0 ? "Solo promo" : "Group promo"}</span>
                                                        <span>{object.title}</span>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })
                        }
                        {
                            isHasNextPage || hasNextPage ? <div className={css.loading} ref={ref}>
                                <span>Loading..</span>
                            </div> : <span></span>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Search
