import './App.css';
import Landing from './Pages/LandingPage/Landing';
import { Routes, Route,  } from 'react-router-dom';
import Login from './Pages/AuthPage/Login';
import Homescreen from './Pages/Homepage/Homescreen';
import Activities from './Pages/Activities/Activities';
import Search from './Pages/Search/Search';
import Community from './Pages/Community/Community';
import Favourite from './Pages/Favourites/Favorite';
import Profile from './Pages/Profile/Profile';
import User from './Pages/UserDetail/User';
import MobileFav from './Pages/MobilFav/MobileFav';
import UserMobile from './Pages/UserMobileDetails/UserMobile';
import Support from './Pages/Support/Support';
import Business from './Pages/Business/Business';
import EditProfile from './Pages/EditProfile/EditProfile';
import Update from './Pages/UpdateProfile/Update';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notifications from './Pages/Notification/Notification';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route element={<Homescreen  />}>
          <Route path="/home" element={<Activities />} />
          <Route path="/explore" element={<Search />} />
          <Route path="/business/:id" element={<Business />} />
          <Route path="/community" element={<Community />} />
          <Route path="/favorites" element={<Favourite />} />
          <Route path="/favorites/:id" element={<MobileFav />} />
          <Route path="/explore/:id" element={<MobileFav />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/profile/update/:id/:uid" element={<Update />} />
          <Route path="/profile/:username" element={<EditProfile/>} />
          <Route path="/profile/support" element={<Support />} />
          <Route path="/community/:id" element={<User />} />
          <Route path="/mobile/community/:id" element={<UserMobile />} />
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
